import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { useInView } from "react-hook-inview";
import AOS from "aos";

import {
    Button,
    Stack,
} from '@mui/material';

 import * as Constants from "../../Constants.js";

import AccordionItem from "../CustomeAccordion/AccordionItem"
import AccordionWrapper from "../CustomeAccordion/AccordionWrapper"

import FeaturesData from "../../Data/Sum4UFeatureData";
import Pic1 from "../../Assets/sum4u_final.png";
import Pic2 from "../../Assets/sum4u_kf.png";

import "../CustomeAccordion/Accordion.css";
import "../../../node_modules/aos/dist/aos.css";

AOS.init({
    once: true
});

const Introduction = () => {
    return (
        <Container fluid className="home-section" id="Sum4U">
            <Container className="home-content">
                <Row>
                    <Col md={6} className="sum4u-introduction-img" 
                        data-aos="fade-right"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={Pic1}
                            className="img-fluid animate__animated animate__fadeIn"
                            loading="lazy"
                            style={{ maxHeight: "600px" }}
                            alt="about_illustration"
                        />
                    </Col>

                    <Col md={6} className="home-challenges-description"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                    >
                        <h1 style={{ fontSize: "1.8em", paddingBottom: 10, color: "#fd7013" }}>
                            Streamline Your News Routine with <span className="orange">ReadNews4U</span>
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Feeling overwhelmed by the constant flood of news? Our AI-powered platform 
                            is here to help.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Our advanced LLM-based AI agents enrich the news content, extracting the 
                            essential insights and relevant information. The result? 
                            Concise, easy-to-digest summaries packed with key insights - empowering 
                            you to grasp the essential information, without getting bogged down in 
                            unnecessary details.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Never miss a beat. With <span className="orange">ReadNews4U</span>, you 
                            can stay informed, boost your productivity, and make better-informed 
                            decisions. Our platform puts the news that matters most right at your 
                            fingertips.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            Get the news you need, faster. Experience the power of 
                            <span className="orange"> ReadNews4U</span> today.
                        </h1>
                        <Button 
                            href="https://readnews4u.com/" 
                            target="_blank" 
                            className="fork-btn-inner"
                            style={{ 
                                height: 60,
                                color: 'white',
                                fontWeight: 600,
                                top: 20,
                            }}
                        >
                            {"Check it out. It's FREE!"}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

const Metrics = () => {
    const [ref, isVisible] = useInView({ threshold: 0 });

    const RenderMetrics = () => {
        return (
            <div className="section metrics">
                <div className="inner">
                    <div className="container">
                        <Stack direction="row" spacing={4} useFlexGap flexWrap="wrap" justifyContent="center">
                            <div
                                className="innerContainer"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                <CountUp
                                    start={0}
                                    end={Constants.SUM4U_NUM_CATEGORIES}
                                    duration={Constants.SUM4U_COUNTUP_DURATION}
                                    separator=" "
                                    decimals={0}
                                    decimal=","
                                    delay={0}
                                >
                                    {({ countUpRef, start }) => (
                                        <div>
                                            <h2 ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <h4>categories</h4>
                            </div>
                            <div
                                className="innerContainer"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="200"
                            >
                                <CountUp
                                    start={0}
                                    end={Constants.SUM4U_NUM_TOPICS}
                                    duration={Constants.SUM4U_COUNTUP_DURATION}
                                    separator=" "
                                    decimals={0}
                                    decimal=","
                                    suffix="+"
                                    delay={0}
                                >
                                    {({ countUpRef, start }) => (
                                        <div>
                                            <h2 ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <h4>tags</h4>
                            </div>
                            <div
                                className="innerContainer"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="000"
                            >
                                <CountUp
                                    start={0}
                                    end={Constants.SUM4U_NUM_SOURCES}
                                    duration={Constants.SUM4U_COUNTUP_DURATION}
                                    separator=" "
                                    decimals={0}
                                    decimal=","
                                    prefix=""
                                    suffix="+"
                                    delay={0}
                                >
                                    {({ countUpRef, start }) => (
                                        <div>
                                            <h2 ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <h4>trusted sources</h4>
                            </div>
                            <div
                                className="innerContainer"
                                data-aos="fade"
                                data-aos-duration="1000"
                                data-aos-delay="400"
                            >
                                <CountUp
                                    start={0}
                                    end={Constants.SUM4U_PROCESSED_ARTICLES}
                                    duration={Constants.SUM4U_COUNTUP_DURATION}
                                    separator=""
                                    decimals={0}
                                    decimal=","
                                    suffix="+"
                                    delay={0}
                                >
                                    {({ countUpRef, start }) => (
                                        <div>
                                            <h2 ref={countUpRef} />
                                        </div>
                                    )}
                                </CountUp>
                                <h4>articles processed per day</h4>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div ref={ref}>{isVisible ? <RenderMetrics /> : <RenderMetrics />}</div>
    );
};

const KeyFeatures = () => {
    let data = FeaturesData.FEATURES;
    return (
        <Container fluid className="home-section" id="Sum4U">
            <Container className="home-content">
                <Row>
                    <Col md={7} className="home-challenges-description"
                        data-aos="fade-right"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                    >
                        <h1 style={{ fontSize: "1.8em", paddingBottom: 30, color: "#fd7013" }}>
                            Explore the innovative capabilities that set ReadNews4U apart
                        </h1>
                        <AccordionWrapper>
                            {data.map((item, index) => (
                                <AccordionItem 
                                    key={index}
                                    index={index} 
                                    title={item.title} 
                                    description={item.content}
                                />
                            ))}
                        </AccordionWrapper>
                    </Col>

                    <Col md={5} className="sum4u-introduction-img" 
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={Pic2}
                            className="img-fluid animate__animated animate__fadeIn"
                            loading="lazy"
                            style={{ maxHeight: "600px", paddingLeft: "10px" }}
                            alt="about_illustration"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

function Sum4U() {
    return (
        <div className="home-section">
            <Introduction />
            <Metrics />
            <KeyFeatures />
        </div>
    );
}

export default Sum4U;
