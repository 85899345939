const FEATURES = [
    {
        'title': "Intelligent Categorization",
        'content': "Automatically organize news articles into relevant topics like business, sports, politics, and more. Easily navigate to the news that aligns with your interests."
    },
    {
        'title': 'Relevance Ranking',
        'content': 'Ensure you receive the most impactful and timely news, prioritized by importance.'
    },
    {
        'title': 'Advanced Search',
        'content': 'Effortlessly explore news using versatile filters for source, tags, keywords, and more. Get precise, actionable search results.'
    },
    {
        'title': 'Contextual Tagging',
        'content': 'Relevant tags provide a quick overview of the key themes and subjects covered in each article.'
    },
    {
        'title': 'Sentiment Analysis',
        'content': 'Gauge the emotional tone and overall sentiment associated with news articles, enhancing your understanding.'
    },
    {
        'title': 'Insightful Summaries',
        'content': 'Concise, informative summaries capture the essential points, saving you time without missing key details.'
    },
    {
        'title': 'Analytical Insights',
        'content': 'Uncover valuable trends and patterns in the news, empowering you with deeper context and understanding.'
    },
    {
        'title': 'Contextual Glossary',
        'content': 'Clear explanations of unfamiliar terms ensure you grasp the full context of news topics.'
    }
];
const FeaturesData={FEATURES};
export default FeaturesData;