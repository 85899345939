import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from 'react-lottie';
import AOS from "aos";

import animationData from "../../Assets/ai_homepage.json"
import "../../../node_modules/aos/dist/aos.css";
import Type from "./Type";

AOS.init({
    once: true
});

function AboutUs() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }};

    return (
        <Container fluid className="home-section" id="about-us">
            <Container className="home-content">
                <Row>
                    <Col lg={7} className="home-header"
                        data-aos="fade-right"
                        data-aos-duration="1500"
                        data-aos-delay="000"
                    >
                        <h1 style={{ paddingBottom: 15 }} className="heading">
                            <span className="orange"> Welcome to Speed AI </span>
                        </h1>

                        <h1 className="heading-name">
                            While AI technology has advanced rapidly in recent years, current solutions 
                            still face critical challenges that hinder widespread adoption:
                        </h1>
                        <div style={{ 
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 50,
                            textAlign: "left" 
                        }}>
                            <Type />
                        </div>

                        <h1 className="heading-name" style={{ paddingBottom: 50 }}>
                            Overcoming these barriers will be pivotal to accelerating the integration of AI 
                            across industries. At 
                            <span className="orange"> Speed AI</span>
                            , we're dedicated to driving this transformation. 
                            We not only develop state-of-the-art
                            <span className="orange"> GenAI-powered products</span>
                            , but also provide comprehensive 
                            <span className="orange"> consulting services </span>
                            to guide businesses through every step of the AI implementation process.
                        </h1>
                    </Col>

                    <Col lg={5}
                        data-aos="fade-left"
                        data-aos-duration="1500"
                        data-aos-delay="000"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Lottie 
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default AboutUs;