import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Lottie from 'react-lottie';
import AOS from "aos";

import applicationData from "../../Assets/applications.json"

import "../../../node_modules/aos/dist/aos.css";
import "../CustomeAccordion/Accordion.css";

AOS.init({
    once: true
});

function ApplicationSection() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: applicationData,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <Container fluid className="home-section" id="challenges">
            <Container>
                <Row>
                    <Col lg={5} className="sum4u-introduction-img" 
                        data-aos="fade-right"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                        style={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Lottie 
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                    </Col>

                    <Col lg={7} className="home-challenges-description"
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        data-aos-delay="000"
                    >
                        <h1 style={{ fontSize: "2.0em", paddingBottom: 10, color: "#fd7013" }}>
                            Automation-Driven AI Solutions
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            At the core of our AI offerings is the conviction that the true power of 
                            AI lies in its capacity to automate repetitive, time-consuming tasks. By 
                            offloading these manual processes, organizations can redirect valuable 
                            resources towards higher-value, strategic initiatives that drive growth 
                            and innovation.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            This guiding principle shapes the development of all our AI products and 
                            solutions. Our goal is to build scalable, industry-tailored applications 
                            that leverage the transformative capabilities of AI to reduce costs, 
                            enhance productivity, and unlock new opportunities for our clients.
                        </h1>
                        <h1 style={{ fontSize: "1.3em", textAlign: 'left', padding: 10 }}>
                            If you're looking to leverage the power of AI to elevate your operations, 
                            we'd be glad to explore how our tailored solutions can help. Get in touch 
                            with us today <a href="mailto:info@speedai.co">info@speedai.co</a>, and 
                            let's discuss how we can support your journey towards AI-powered success.
                        </h1>
                    </Col>
                </Row>
            </Container>
        </Container>
)}
export default ApplicationSection;